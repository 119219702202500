import React from 'react';
import './industries.css';
const Industries = () => {
  const industries = [
    {
      img: '/assets/agriculture.png',
      alt: 'agriculture',
      text: 'Agriculture',
    },
    {
      img: '/assets/consumer.png',
      alt: 'consumer',
      text: 'Consumer',
    },
    {
      img: '/assets/cpg.png',
      alt: 'cpg',
      text: 'Consumer Packaged Goods',
    },
    {
      img: '/assets/cyber_sec.png',
      alt: 'cyber_sec',
      text: 'Cybersecurity',
    },
    {
      img: '/assets/electric_vehicle.png',
      alt: 'electric_vehicle',
      text: 'Electric Vehicle',
    },

    {
      img: '/assets/finance.png',
      alt: 'finance',
      text: 'Finance',
    },
    {
      img: '/assets/food.png',
      alt: 'food',
      text: 'Food',
    },
    {
      img: '/assets/healthcare.png',
      alt: 'healthcare',
      text: 'Healthcare',
    },
    {
      img: '/assets/id.png',
      alt: 'id',
      text: 'Identity Management',
    },
    {
      img: '/assets/insurance.png',
      alt: 'insurance',
      text: 'Insurance',
    },
    {
      img: '/assets/media.png',
      alt: 'media',
      text: 'Media',
    },
    {
      img: '/assets/nbfc.png',
      alt: 'nbfc',
      text: 'NBFC',
    },
    {
      img: '/assets/real_estate.png',
      alt: 'real_estate',
      text: 'Real Estate',
    },
    {
      img: '/assets/retail.png',
      alt: 'retail',
      text: 'Retail',
    },
  ];

  return (
    <div className="industriesContainer" id="industries">
      <div className="industriesHeading"> Industries</div>
      <div className="industriesTagline">
        The wide range of industries we cater to but not limited to include
      </div>

      <div className="industriesImages">
        {industries.map((service, index) => (
          <div key={index} className="industriesImagesCard">
            <img src={service.img} alt={service.alt} />
            <div>{service.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Industries;
