import React from 'react';
import './technologies.css';
const Technologies = () => {
  const technologies = [
    {
      img: '/assets/aws.png',
      alt: 'aws',
    },
    {
      img: '/assets/bigdata.png',
      alt: 'bigdata',
    },
    {
      img: '/assets/caffe.png',
      alt: 'caffe',
    },
    {
      img: '/assets/db.png',
      alt: 'db',
    },
    {
      img: '/assets/keras.png',
      alt: 'keras',
    },

    {
      img: '/assets/pitorch.png',
      alt: 'pitorch',
    },
    {
      img: '/assets/powerbi.png',
      alt: 'powerbi',
    },
    {
      img: '/assets/pyspark.png',
      alt: 'pyspark',
    },
    {
      img: '/assets/python.png',
      alt: 'python',
    },
    {
      img: '/assets/tableaur.png',
      alt: 'tableaur',
    },
    {
      img: '/assets/salesforce.png',
      alt: 'salesforce',
    },
    {
      img: '/assets/tensorflow.png',
      alt: 'tensorflow',
    },

    {
      img: '/assets/uipath.png',
      alt: 'uipath',
    },
  ];

  return (
    <div className="technologiesContainer" id="technologies">
      <div className="technologiesHeading"> Technologies</div>

      <div className="technologiesImages">
        {technologies.map((service, index) => (
          <div key={index} className="technologiesImagesCard">
            <img src={service.img} alt={service.alt} />
            <div>{service.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Technologies;
