import React from 'react';
import './home.css';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="home" id='home'>
 
      <nav className="navbar">
        <div className="leftNav">
          <img src="assets/dp.png" alt="dplogo"  />
          <span>Digiplug </span>
        </div>
        <div className="rightNav">
          <Link
            className="link"
            onClick={()=>navigate('/')}
          >
            About Us
          </Link>
          <Link
            to="services"
            spy={true}
            smooth={true}
            offset={10}
            duration={700}
            className="link"
          >
            Services
          </Link>
          <Link
            to="industries"
            spy={true}
            smooth={true}
            offset={10}
            duration={800}
            className="link"
          >
            Industries
          </Link>
          <Link
            to="technologies"
            spy={true}
            smooth={true}
            offset={10}
            duration={800}
            className="link"
          >
            Technologies
          </Link>

          <Link
            to="product"
            spy={true}
            smooth={true}
            offset={10}
            duration={700}
            className="link"
          >
            Product
          </Link>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={10}
            duration={800}
            className="link"
          >
            Join Us
          </Link>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={10}
            duration={800}
            className="contact link"
          >
            Contact
          </Link>

          <div className="hamburgerIcon">
            <img src="/assets/hamburger.png" alt="" />
          </div>
        </div>
      </nav>

      <div className="middle">
        <p className="text">
          Transforming, digitizing, and enabling industries across the globe to
          grow through data-backed and AI-based solutions and strategies.
        </p>

        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={10}
          duration={500}
          className="contactBtn"
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
};

export default Home;
