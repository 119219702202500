import React from 'react';
import './products.css';
const Product = () => {
  const product= [
    {
      img: '/assets/plug_pay.jpg',
      alt: 'plug_pay.jpg',
    },
    {
      img: '/assets/engageAI.png',
      alt: 'engageAI',
    },
    

  ];

  return (
    <div className="productContainer" id="product">
      <div className="productHeading"> Products</div>
      

      <div className="productImages">
        {product.map((service, index) => (
          <div key={index} className="productImagesCard">
            <img src={service.img} alt={service.alt} />
            <div>{service.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
