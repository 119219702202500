import React from 'react';
import './numbers.css';
const Numbers = () => {
  return (
    <div className="numberContainer" id='numbers'>
      {/* Center Text */}
      <div className="centerText">
        We are a customer focused & AI-driven Data Science & Analytics
        Consulting startup having worked with customers across wide range of
        industries.
      </div>
      {/* Numbers and  two cards  */}
      <div className="ourNumbers">
        <p className="heading">Our Numbers</p>

        <div className="projectsCards">
          <div className="project">
            <img src="/assets/projects.svg" alt="" />
            <div className="projectsCardsNumber">20+</div>
            <div className="projectsCardsTypography">
              Projects Successfully Delivered
            </div>
          </div>
          <div className="customers">
            <img src="/assets/customers.png" alt="" />
            <div className="projectsCardsNumber">10+</div>
            <div className="projectsCardsTypography">Happy Customers</div>
          </div>
        </div>
      </div>
      {/* Stories */}

      {/* <div className="stories">
        <div className="storiesHeading">
          <div className="storiesHeadingText">Case Studies and Stories</div>
          <button className="storiesHeadingBtn">View All</button>
        </div>

        <div className="storiesCards">
          <div className="card">
            <div className="cardBlankImg"></div>
            <div className="cardHeading">Case Study 1 : NBFC Startup</div>
            <div className="cardText">
              fast growing startup in digital lending space were disbursing
              loans to people based on their needs because Read more......
            </div>
          </div>

          <div className="card">
            <div className="cardBlankImg"></div>
            <div className="cardHeading">Case Study 1 : NBFC Startup</div>
            <div className="cardText">
              fast growing startup in digital lending space were disbursing
              loans to people based on their needs because Read more......
            </div>
          </div>
          
          <div className="card">
            <div className="cardBlankImg"></div>
            <div className="cardHeading">Case Study 1 : NBFC Startup</div>
            <div className="cardText">
              fast growing startup in digital lending space were disbursing
              loans to people based on their needs because Read more......
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Numbers;
