import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import './footer.css';
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footerContainer">
      <div className="footerLeft">
        <div className="footerHeading">Digiplug Technologies </div>
        <a
          href="https://www.linkedin.com/company/digiplug-technologies-private-limted/about/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/assets/linkedin.svg"
            alt="linkedin"
            className="footerImg"
          />
        </a>
        {/* <img
          src="/assets/facebook.svg"
          alt="facebook"
          className="footerImg facebook footerImgMargin"
        />
        <img
          src="/assets/instagram.svg"
          alt="instagram"
          className="footerImg footerImgMargin"
        />
        <img
          src="/assets/twitter.svg"
          alt="twitter"
          className="footerImg footerImgMargin"
        /> */}

        <div className="footerText">
          Digiplug Technologies is on a mission to transform, digitize, and
          enable businesses across industries to grow through using real-time
          data and decision science powered by AI.
        </div>
      </div>

      <div className="footerRight">
        <div className="footerCompany">
          <div className="companyHeader">Company</div>
          <div className="footerLinks" onClick={() => navigate('/terms')}>
            Terms of service
          </div>
          <div className="footerLinks" onClick={() => navigate('/privacy')}>
            Privacy policy
          </div>
          {/* <div className="footerLinks">Legal notice</div> */}
          <div className="footerLinks">
            <Link
              to="home"
              spy={true}
              smooth={true}
              offset={10}
              duration={700}
              className="link"
            >
              About
            </Link>
          </div>
          {/* <div className="footerLinks">Careers</div> */}
        </div>

        <div className="footerContact">
          <div className="companyHeader">Contact</div>
          <div className="footerLinks">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={10}
              duration={700}
              className="link"
            >
              Contact Us
            </Link>
          </div>
          {/* <div className="footerLinks">Support</div> */}
          <div className="mobile">
            <img
              src="/assets/phone.png"
              alt="mobileIcon"
              className="mobileIcon"
            />
            <div>+91-9925809898</div>
          </div>
          <div className="email">
            <img
              src="/assets/email.png"
              alt="emailIcon"
              className="emailIcon"
            />
            <div >digiplugtech@gmail.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
