import React from 'react'
import Home from '../Home/Home'
import Footer from '../Footer/Footer'

const Terms = () => {
  return (
    <div>
        <Home/>
        <Footer/>
    </div>
  )
}

export default Terms