import React from 'react';
import Services from '../Services/Services';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import Home from '../Home/Home';
import Numbers from '../Numbers/Numbers';
import Industries from '../../Industries/Industries';
import Products from '../../Products/Products';
import Technologies from '../../Technologies/Technologies';
import Product from '../../Products/Products';


const Main = () => {
  return (
    <>
      <Home />
      <Numbers />
      <Services />
      <Industries/>
      <Technologies/>
      <Product/>
      <Contact />
      <Footer />
    </>
  );
};

export default Main;
