import React from 'react';
import './services.css';
const Services = () => {
  const services = [
    {
      img: '/assets/ai.png',
      alt: 'ai',
      text: 'Artificial Intelligence',
    },
    {
      img: '/assets/cv.png',
      alt: 'cv',
      text: 'Computer vision',
    },
    {
      img: '/assets/de.png',
      alt: 'de',
      text: 'Data Engineering',
    },
    {
      img: '/assets/ds.png',
      alt: 'ds',
      text: 'Data Science',
    },
    {
      img: '/assets/nlp.png',
      alt: 'nlp',
      text: 'Natural Language Processing',
    },

    {
      img: '/assets/cv2.png',
      alt: 'cv2',
      text: 'Computer vision',
    },
    {
      img: '/assets/dv.png',
      alt: 'dv',
      text: 'Data Visualization',
    },
    {
      img: '/assets/rpa.png',
      alt: 'rpa',
      text: 'Robotic Process Automation',
    },
  ];

  return (
    <div className="servicesContainer" id='services'>
      <div className="serviceHeading">Our Services</div>

      <div className="servicesImages">
        {services.map((service, index) => (
          <div key={index} className="servicesImagesCard">
            <img src={service.img} alt={service.alt} />
            <div>{service.text}</div>
          </div>
        ))}
      </div>

      <div className="insights">
        <div className="insightsHeading">
          Our Insights to help you stay ahead of change
        </div>

        <div className="insightsMain">
          {/* Puzzle 1 */}
          <div className="insightsZigZagCards">
            <img src="/assets/puzzle1.svg" alt="" className='puzzleCardImg' />
            <div className='puzzleHeading'>Evaluate</div>
            <div className="insightsText">
              We evaluate your existing capabilities, enabling you to priorities
              strategic investments and to lay the foundations for future
              success.
            </div>

            <div className="insightsRectangle puzzleCardImg" ></div>
          </div>

          {/* Puzzle 2 */}
          <div className="insightsZigZagCards">
            <div className="insightsCircle puzzleCardImg"></div>
            <div className='puzzleHeading'>Design</div>
            <div className="insightsText">
              We design a modern enterprise-level data strategy aligned to your
              business goals to unlock the value of your data, quickly.
            </div>

            <img src="/assets/puzzle2.svg" alt=""  className='puzzleCardImg'/>
          </div>

          {/* Puzzle 3 */}
          <div className="insightsZigZagCards">
            <img src="/assets/puzzle3.svg" alt="" className='puzzleCardImg' />
            <div className='puzzleHeading'>Build</div>
            <div className="insightsText">
              We take you from concept to production, building data platforms,
              systems, products and operating models underpinned by the latest
              tools, technologies and methodologies.
            </div>

            <img src="/assets/traingle.svg" alt="traingle" className='puzzleCardImg'/>
          </div>

          {/* Puzzle 4 */}
          <div className="insightsZigZagCards">
            <img src="/assets/pentagon.svg" alt="pentagon" className='puzzleCardImg'/>
            {/* Headline For Smaller Screen */}
            <div className='puzzleHeading'>OutPerform</div>
            <div className="insightsText">
              We grow your data capabilities through AI and Machine Learning to
              unlock the full potential of your data. Enabling you to outperform
              your competitors.
            </div>
            <img src="/assets/puzzle4.svg" alt="" className='puzzleCardImg'/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
