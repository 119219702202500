import React, { useEffect } from 'react';

import ReactGA from 'react-ga';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './components/Main/Main';
import Terms from './components/Terms/Terms';
import Support from './Support/Support';

const App = () => {
  ReactGA.initialize('G-MXSLTF5VPN');
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Terms />} />
        <Route path="/support" element={<Support />} />



      </Routes>
    </BrowserRouter>
  );
};

export default App;
