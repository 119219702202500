import React, { useState } from 'react';
import './contact.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const api = process.env.REACT_APP_API_PATH;

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    message: '',
  });
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    // Perform your form submission logic here, and validate the inputs.
    // You can set errors in the setError state if any input is invalid.

    if (
      formData.firstName === '' ||
      formData.lastName === '' ||
      formData.email === '' ||
      formData.company === '' ||
      formData.message === ''
    ) {
      setError('All fields are required.');
    } else {
      setError('');

      try {
        const res = await axios.post(`${api}/contact`, {
          digiplug_user_form_fisrtName: formData.firstName,
          digiplug_user_form_lastName: formData.lastName,
          digiplug_user_form_email: formData.email,
          digiplug_user_form_company: formData.company,
          digiplug_user_form_message: formData.message,
        });

        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          message: '',
        });

        toast.success('Details Saved Succesfully', {
          position: 'top-center',
          pauseOnHover: true,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="contactContainer" id="contact">
      <div className="contactHeading">Contact Us</div>

      <div className="contactForm">
        <div className="contactInputWrapper">
          <input
            className="contactInputWrapperInput"
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleInputChange}
          />
          <input
            className="contactInputWrapperInput"
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </div>
        <input
          className="contactInput"
          type="text"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
        />
        <input
          className="contactInput"
          type="text"
          name="company"
          placeholder="Company"
          value={formData.company}
          onChange={handleInputChange}
        />
        <textarea
          className="contactTextarea"
          name="message"
          id=""
          cols="30"
          rows="10"
          placeholder="Message"
          value={formData.message}
          onChange={handleInputChange}
        />
        {error && <p className="error">{error}</p>}
        <button className="submitBtn" onClick={handleSubmit}>
          Submit
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
