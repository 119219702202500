import React from 'react'
import Home from '../components/Home/Home'
import Footer from '../components/Footer/Footer'

const Support = () => {
  return (
    <div>
        <Home/>
        <Footer/>
    </div>
  )
}

export default Support